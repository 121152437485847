import React from 'react'

import styles from '../css-modules/footer.module.css'

class Footer extends React.Component {
  render() {
    return (
      <div className={styles.footer}>
        <div>
          <a href="/">
            <img src="/static/icons/ISAPS_Approved_white.png" />
          </a>
        </div>
      </div>
    )
  }
}

export default Footer
